import React from "react";
import HeadingSmall from "../components/HeadingSmall.component";
import CommitteeTable from "../components/CommitteeTable.component";

const BoardOfDirectors = () => {
  const boardOfDirectorHeading = {
    fontSize: "18px",
    color: "#000",
  };
  const boardOfDirectorSubHeading = {
    fontSize: "14px",
  };

  const committees = [
    {
      name: "COMMITTEES OF BOARD",
      details:
        "The Board of Directors of the Company has constituted the following Committees with a view to have more focused attention on the business and for better accountability. Each of these Committees has been mandated to operate within a given framework.",
      members: [
        {
          name: "Dr. Arun Arora",
          position: "Chairman and Independent Director",
        },
        {
          name: "Mr. Sivakumar Sundaram",
          position: "Non-Executive Director",
        },
        { name: "Ms. Mitu Samarnath Jha", position: "Non-Executive  and Independent Director" },
        { name: "Ms. Anita Malusare", position: "Executive Director & CEO" },
        {
          name: "Mr. Gopalkrishnan Ramaswamy",
          position: "Non-Executive Director",
        },
        { name: "Mr. Vikesh Wallia", position: "Non-Executive  and Independent Director " },
        { name: "Mr. M Lakshminarayanan", position: "Non-Executive Director" },
      ],
    },
    {
      name: "Audit Committee",
      details:
        "The Board has constituted an Audit Committee consisting of the following:",
      members: [
        { name: "Ms. Mitu Samarnath Jha", position: "Chairperson" },
        { name: "Mr. Sivakumar Sundaram", position: "Member" },
        { name: "Dr. Arun Arora", position: "Member" },
        { name: "Mr. Vikesh Wallia", position: "Member" },
      ],
    },
    {
      name: "Nomination and Remuneration Committee",
      details:
        "The Board has constituted the Nomination & Remuneration Committee consisting of the following:",
      members: [
        { name: "Ms. Mitu Samarnath Jha", position: "Chairperson" },
        { name: "Mr. Sivakumar Sundaram", position: "Member" },
        { name: "Dr. Arun Arora", position: "Member" },
        { name: "Mr. Vikesh Wallia", position: "Member" },
      ],
    },
    {
      name: "Corporate Social Responsibility Committee",
      details:
        "The Board has constituted the Corporate Social Responsibility Committee consisted of the following:",
      members: [
        { name: "Ms. Mitu Samarnath Jha", position: "Chairperson" },
        { name: "Mr. Gopalkrishnan Ramaswamy", position: "Member" },
        { name: "Mr. M Lakshminarayanan", position: "Member" },
      ],
    },
    {
      name: "Stakeholders Relationship Committee",
      details:
        "The Board has constituted the Stakeholders Relationship Committee consisting of the following:",
      members: [
        { name: "Mr. Sivakumar Sundaram", position: "Chairman" },
        { name: "Ms. Mitu Samarnath Jha", position: "Member" },
        { name: "Mr. Gopalkrishnan Ramaswamy", position: "Member" },
      ],
    },
    
  ];

  return (
    <>
      <table
        border="0"
        align="center"
        cellPadding="0"
        cellSpacing="0"
        className="tablebg commonWidth top_margin"
      >
        <tbody>
          <tr>
            <td>
              <table
                className="commonWidth"
                border="0"
                align="center"
                cellPadding="0"
                cellSpacing="0"
              >
                <tbody>
                  <HeadingSmall name={"Board Of Directors "} />
                  {/* <tr>
                    <td align="left" valign="top">
                      <table
                        width="500"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <tbody>
                          <tr>
                            <td align="left" valign="top">
                              &nbsp;
                            </td>
                            <td align="left" valign="top">
                              &nbsp;
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              valign="top"
                              className="boardofdirectors-names"
                            >
                              <strong>Dr. Arun Arora</strong>
                            </td>
                            <td
                              align="left"
                              valign="top"
                              className="boardofdirectors-names"
                            >
                              Chairman and Independent Director
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              valign="top"
                              className="boardofdirectors-names"
                            >
                              <strong> Mr. Sivakumar Sundaram</strong>
                            </td>
                            <td
                              align="left"
                              valign="top"
                              className="boardofdirectors-names"
                            >
                              Non-Executive Director
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              valign="top"
                              className="boardofdirectors-names"
                            >
                              <strong>Ms. Mitu Samarnath Jha</strong>
                            </td>
                            <td
                              align="left"
                              valign="top"
                              className="boardofdirectors-names"
                            >
                             	Independent Director
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              valign="top"
                              className="boardofdirectors-names"
                            >
                              <strong>Mr. Gopalkrishnan Ramaswamy</strong>
                            </td>
                            <td
                              align="left"
                              valign="top"
                              className="boardofdirectors-names"
                            >
                             Non-Executive Director
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              valign="top"
                              className="boardofdirectors-names"
                            >
                              <strong>Mr. Jayaprakash Nair</strong>
                            </td>
                            <td
                              align="left"
                              valign="top"
                              className="boardofdirectors-names"
                            >
                             Non-Executive Director
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              valign="top"
                              className="boardofdirectors-names"
                            >
                              <strong>Ms. Anita Malusare </strong>
                            </td>
                            <td
                              align="left"
                              valign="top"
                              className="boardofdirectors-names"
                            >
                             	Executive Director & CEO
                            </td>
                          </tr>
                          <tr>
                            <td align="left" valign="top">
                              &nbsp;
                            </td>
                            <td align="left" valign="top">
                              &nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="100%"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <div>
                                <p style={boardOfDirectorHeading}>
                                  <strong>COMMITTEES OF BOARD</strong>
                                </p>
                                <p style={boardOfDirectorSubHeading}>
                                  The Board of Directors of the Company has
                                  constituted the following Committees with a
                                  view to have more focused attention on the
                                  business and for better accountability. Each
                                  of these Committees has been mandated to
                                  operate within a given framework.
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr> */}
                  {committees.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item.name === "COMMITTEES OF BOARD" ? (
                          <>
                            <tr>
                              <td align="left" valign="top">
                                <table
                                  width={
                                    item.name === "COMMITTEES OF BOARD"
                                      ? "500"
                                      : "400"
                                  }
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                >
                                  
                                  <tbody>
                                  <tr>
                                      <td align="left" valign="top">
                                        &nbsp;
                                      </td>
                                      <td align="left" valign="top">
                                        &nbsp;
                                      </td>
                                    </tr>
                                    {item.members.map((item, index) => (
                                      <CommitteeTable
                                        key={index}
                                        name={item.name}
                                        position={item.position}
                                        index={index}
                                      />
                                    ))}
                                   <tr>
                                      <td align="left" valign="top">
                                        &nbsp;
                                      </td>
                                      <td align="left" valign="top">
                                        &nbsp;
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td align="left" valign="top">
                                <table
                                  width="100%"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                >
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div>
                                          <p style={boardOfDirectorHeading}>
                                            <strong>{item.name}</strong>
                                          </p>
                                          <p style={boardOfDirectorSubHeading}>
                                            {item.details}
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <>
                            <tr>
                              <td align="left" valign="top">
                                <table
                                  width="100%"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                >
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div>
                                          <p style={boardOfDirectorHeading}>
                                            <strong>{item.name}</strong>
                                          </p>
                                          <p style={boardOfDirectorSubHeading}>
                                            {item.details}
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td align="left" valign="top">
                                <table
                                  width={
                                    item.name === "COMMITTEES OF BOARD"
                                      ? "500"
                                      : "400"
                                  }
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                >
                                  <tbody>
                                    {item.members.map((item, index) => (
                                      <CommitteeTable
                                        key={index}
                                        name={item.name}
                                        position={item.position}
                                        index={index}
                                      />
                                    ))}
                                    <tr>
                                      <td align="left" valign="top">
                                        &nbsp;
                                      </td>
                                      <td align="left" valign="top">
                                        &nbsp;
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default BoardOfDirectors;
